    <!-- Navigation -->
    <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand js-scroll-trigger" href="#page-top">Brian Alan Hager</a>
        <button class="navbar-toggler navbar-toggler-right " type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" data-toggle="collapse" data-target=".navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#page-top">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#events">Upcoming Gigs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#video">Videos</a>
            </li>            
            <li class="nav-item active">
              <a class="nav-link js-scroll-trigger" href="#about">About</a>
            </li>
            <!--<li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#photos">Photos</a>
            </li>-->
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="https://linktr.ee/brianalanhager">Listen</a>
            </li>
            <!--<li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="https://brianalanhager.square.site/">Merch</a>
            </li>-->
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#press">Press</a>
            </li>            
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#booking">Booking</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

