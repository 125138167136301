<section id="video" class="container-fluid bg-dark text-white">
    <div class="text-center">
      <h2 class="mb-4">Video</h2>
      <i>Video of solo cover, original or band shows below. More video on <a href="https://youtube.com/@brianalanhager">YouTube channel</a></i>
      <br>
      <br>
      <h5 class="mb-4">The Brian Alan Hager Band</h5>
      <iframe title="The Brian Alan Hager Band - Waiting On Nothing - Live at BBC - 2024" width="336" height="189" src="https://www.youtube.com/embed/clz4DzzxDF8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe id="nxs-video-iframe" data-frame-src="10201266" width="336" height="189" sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox" layout="responsive" src="https://redir1.fox8.com/nxs-video-player/eyJ2aWRlb19pZCI6IjEwMjAxMjY2IiwicG9zdF9pZCI6MzA3NDU4NCwiYWR0YWdfdXJsIjoiYXR1X3AxP3N6PTF4MTAwMCZpdT1cLzU2NzhcL254Lndqd1wvbW9ybmluZ19zaG93XC9qdWtlYm94JmltcGw9cyZnZGZwX3JlcT0xJmVudj12cCZvdXRwdXQ9dm1hcCZ1bnZpZXdlZF9wb3NpdGlvbl9zdGFydD0xJmFkX3J1bGU9MSZkZXNjcmlwdGlvbl91cmw9YXR1X3AyJmN1c3RfcGFyYW1zPXZpZD0xMDIwMTI2NiZzdGF0aW9uPVdKVyZjbXNpZD0zMDc0NTg0JnBpZD0zMDc0NTg0JnBlcnNfY2lkPW54c3RyaWItMTItYXJ0aWNsZS0zMDc0NTg0JnZpZGNhdD1cL21vcm5pbmdfc2hvd1wvanVrZWJveCZib2JfY2s9W2JvYl9ja192YWxdJmRfY29kZT0xJnBhZ2V0eXBlPXN0b3J5JmhsbWV0YT1mb3ggOCBqdWtlYm94IGJyaWFuIGFsYW4gaGFnZXIgYmFuZCZhYT1mIiwicG9zdF90eXBlIjoicG9zdCIsImluamVjdGVkX3ZpYSI6ImFtcCIsImFkX3Byb3ZpZGVyIjoiZ2FtIiwiaW5fd3BfZWRpdG9yIjpmYWxzZSwiYWxsb3dfYXV0b3BsYXkiOnRydWUsImlzX2xpdmVibG9nIjpmYWxzZX0=" scrolling="no" frameborder="0" allowfullscreen>The Brian Alan Hager Band on Fox 8 News in the Morning - I'm Alice In A Wonderland</iframe>
      <iframe title="The Brian Alan Hager Band - A Dream - Live at BBC - 2024" width="336" height="189" src="https://www.youtube.com/embed/0ux8kmJjOZQ?si=ZBoN_tg3NhzNyz1Z" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="The Brian Alan Hager Band - Rehearal - 2024" width="336" height="189" src="https://www.youtube.com/embed/1_9PUeEzK4E?si=s2zCqLX2g_CJiP1f" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      
      <br>
      <br>
      <h5 class="mb-4">Solo Shows</h5>
      <iframe title="Brian Alan Hager - 2024 Covers Show Summary" width="336" height="189" src="https://www.youtube.com/embed/UEHgwCPi20g?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
      <iframe title="Brian Alan Hager - 2023 Live highlights" width="336" height="189" src="https://www.youtube.com/embed/-KXxXBcp-w8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - 2022 Live highlights" width="336" height="189" src="https://www.youtube.com/embed/_i5ljcCLttY?si=O4IEE6AJEMtEbZ-X" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br>
      <br>
      <!--<h5 class="mb-4">Music Videos</h5>
      <iframe title="Brian Alan Hager - Spirit Sway" width="336" height="189" src="https://www.youtube.com/embed/gLOMtDHKis8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - Waiting On Nothing" width="336" height="189" src="https://www.youtube.com/embed/3oJd6BcQ51k?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - I See Angles" width="336" height="189" src="https://www.youtube.com/embed/VsvuuzyuLDI?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - I Don't Feel" width="336" height="189" src="https://www.youtube.com/embed/c4xC0g6ciUY?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - The Race" width="336" height="189" src="https://www.youtube.com/embed/70NDWHmlBhM?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br>
      <br> -->
      <h5 class="mb-4">Interviews</h5>
      <iframe title="Level Up Cleveland" width="336" height="189" src="https://www.youtube.com/embed/OUHLGH8R1a4?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
  </section>
